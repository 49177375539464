/* eslint-disable no-unused-vars */
import React from "react";
import cn from "classnames";
import styles from "./Hero.module.sass";
// import Search from "./Search";
import Dummy from "../Features/img/dummyg.png";
import Image from "./Image"
import Text from "./Text";

  
  const Hero = () => {
    return (
      <div className={styles.full}>
        <Text />
        <Image />
      </div>
    );
  };
  
  export default Hero;
  