/* eslint-disable no-unused-vars */
import React from "react";
import styles from "./Image.module.sass";
import Sample from "../../../../mocks/svg/Sample-Vine.svg";


  const Image = () => {
    return (
        <div className={styles.half}>
                <img src={Sample} alt="dummy" />
        </div>   
    );
  };
  
  export default Image;
  