import React from "react";
import styles from "./Form.module.sass";
import Entry from "./Entry";

const Form = () => {

  return (
    <div className={styles.col}>
      <Entry />
    </div>
  );
};

export default Form;
