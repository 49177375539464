/* eslint-disable no-unused-vars */
import React, { useState }  from "react";
import cn from "classnames";
import styles from "./Footer.module.sass";
import { Link, NavLink } from "react-router-dom";
import Icon from "../Icon";
import Logo from "./../../../src/logo.svg";
import Image from "../Image";

const socials = [
  {
    title: "LinkedIn",
    icon: "linkedin",
    url: "https://linkedin.com/company/maxearch",
  },
  {
    title: "Twitter",
    icon: "twitter",
    url: "https://twitter.com/maxearch",
  },
  {
    title: "Instagram",
    icon: "instagram",
    url: "https://instagram.com/maxearch",
  },
];

  const Footer = ({ className, onOpen, onClose }) => {
    const [visible, setVisible] = useState(false);
    const handleClick = () => {
      onOpen();
      setVisible(true);
    };

  return (
    <footer className={cn(styles.footer, className, { [styles.active]: visible })}>
        <Link className={styles.logo} to="/" onClick={onClose}>
          <Image
            className={styles.pic}
            src={Logo}
            alt="Maxearch"
          />
        </Link>
        <div className={styles.copyright}>Copyright Maxearch 2022. All rights reserved.</div>
        <div className={styles.btns}>
          {socials.map((x, index) => (
            <a
              className={cn("link", styles.button)}
              href={x.url}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <Icon name={x.icon} size="24" />
            </a>
          ))}
        </div>

    </footer>
  );
};

export default Footer;
