/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import Icon from "../Icon";
import Image from "../Image";
import Logo from "./../../../src/long-logo-dark.svg";
// import Dropdown from "../Dropdown"
// import NavList from "./NavList";

  const Header = ({ className, onClose, onOpen }) => {
    const [visible, setVisible] = useState(false);
  
  return (
    <header className={cn(styles.header, className, { [styles.active]: visible })}>
        <Link className={styles.logo} to="/" onClick={onClose}>
          <Image
            className={styles.pic}
            src={Logo}
            alt="Maxearch"
          />
        </Link>

        {/* <button className={styles.burger} onClick={() => setVisible(true)}>
        </button> */}

        {/* <NavList />
        
        
      <div className={styles.btns} onClick={() => setVisible(false)}>
        <Link className={cn("button-stroke", styles.button)} to="/register">
          Create account
        </Link>
      </div>

      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}>
          <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
          </button>
      </div> */}
    </header>
  );
};

export default Header;