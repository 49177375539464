/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import styles from "./Features.module.sass";
import Overview from "./Overview";
// import SectionL from "./SectionL";
// import SectionR from "./SectionR";
import cn from "classnames"

const Features = () => {
    return (
        <>
      <div className={styles.section}>
        <Overview className={styles.card} />
        {/* <div className={cn("h3", styles.full, styles.secTitle)}>Browse, collect, and present data seamlessly</div> */}
        {/* <SectionL /> */}
        {/* <SectionR /> */}
      </div>  
    </>
  );
};

export default Features;


