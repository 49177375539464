/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import cn from "classnames";
import styles from "./Subscription.module.sass";
import Form from "./Form"

const Subscription = () => {
  return (
    <>
    <div className={styles.full}>
      <div className={cn("h3", styles.secTitle)}>Maximize your research outcome with <span>Maxearch</span></div>
      <div className={cn("h6", styles.helper)}>Subscribe to the latest news</div>
      <Form />
      </div>
    </>
  );
};

export default Subscription;
