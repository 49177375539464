/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import cn from "classnames";
import styles from "./Page.module.sass";
import Header from "../Header";
import NavList from "../Header/NavList";
import NavColumn from "../Header/NavColumn";
import Footer from "../Footer";

const Page = ({ wide, children, title }) => {
  const [visible, setVisible] = useState(false);

  return (
      <div className={styles.page}>
        {/* <NavList className={cn(styles.menu, { [styles.visible]: visible })}
          onClose={() => setVisible(false)} /> */}
        {/* <NavColumn className={cn(styles.menu, { [styles.visible]: visible })}
          onClose={() => setVisible(false)} /> */}
        <Header onOpen={() => setVisible(true)} />
        <div className={styles.inner}>
          <div
            className={cn(styles.container, {
              [styles.wide]: wide,
            })}
          >
            {title && <div className={cn("h3", styles.title)}>{title}</div>}
            {children}
          </div>
        </div>
        <Footer />
      </div>
  );
};

export default Page;
