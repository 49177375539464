/* eslint-disable no-unused-vars */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import "./twpub.css"

function App() {
  return (
    <Router>
      <Page>
      <Routes>
        <Route
        
          path="/" element={<Home />}
          render={() => (
            <Page title="Home">
              <Home />
            </Page>
          )}
        />

      </Routes>
      </Page>
    </Router>
  );
}

export default App;
