import React from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Card from "../../../../components/Card";
import Icon from "../../../../components/Icon";
// import Chart from "./Chart";

const items = [
  {
    title: "Failures",
    counter: "5,983",
    icon: "activity",
    value: 37.8,
    background: "#FFE7E4",
    chartColor: "#83BF6E",
    data: [
      {
        name: "1",
        earning: 500,
      },
      {
        name: "2",
        earning: 800,
      },
      {
        name: "3",
        earning: 1100,
      },
      {
        name: "4",
        earning: 900,
      },
      {
        name: "5",
        earning: 1300,
      },
      {
        name: "6",
        earning: 800,
      },
      {
        name: "7",
        earning: 1000,
      },
    ],
  },
  {
    title: "Active ventures",
    counter: "15,512",
    icon: "activity",
    value: -37.8,
    background: "#edf8f2",
    chartColor: "#2A85FF",
    data: [
      {
        name: "1",
        earning: 1300,
      },
      {
        name: "2",
        earning: 800,
      },
      {
        name: "3",
        earning: 1000,
      },
      {
        name: "4",
        earning: 500,
      },
      {
        name: "5",
        earning: 800,
      },
      {
        name: "6",
        earning: 1100,
      },
      {
        name: "7",
        earning: 900,
      },
    ],
  },
  {
    title: "Just launch (within a month)",
    counter: "640",
    icon: "activity",
    value: 37.8,
    background: "#f2efff",
    chartColor: "#8E59FF",
    data: [
      {
        name: "1",
        earning: 1200,
      },
      {
        name: "2",
        earning: 800,
      },
      {
        name: "3",
        earning: 1300,
      },
      {
        name: "4",
        earning: 600,
      },
      {
        name: "5",
        earning: 1300,
      },
      {
        name: "6",
        earning: 800,
      },
      {
        name: "7",
        earning: 1000,
      },
    ],
  },
];

const Overview = ({ className }) => {

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Recent technology updates"
        classTitle="title-purple"
      >
        <div className={styles.overview}>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div
                className={styles.item}
                key={index}
                style={{ backgroundColor: x.background }}
              >
                {/* <div className={styles.icon}>
                  <Icon name={x.icon} size="24" />{" "}
                </div> */}
                <div className={styles.line}>
                  <div className={styles.details}>
                    <div className={styles.counter}>{x.counter}</div>
                    <div className={styles.category}>
                      {x.title}
                    </div>
                  </div>
                  {/* <Chart className={styles.chart} item={x} /> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
    </>
  );
};

export default Overview;
