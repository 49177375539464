import React from "react";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../../../components/TextInput";

const Entry = ({ onConfirm }) => {
  return (
      <div className={styles.body}>
        <div className={styles.wrap}>
        <TextInput
          className={styles.field}
          name="email"
          type="email"
          placeholder="Your email"
          required
          icon="mail"
        />
        <button className={cn("button", styles.button)} onClick={onConfirm}>
          Subscribe
        </button>
        </div>
      </div>
  );
};

export default Entry;
