/* eslint-disable no-unused-vars */
import React, { useEffect, render } from "react";
import cn from "classnames";
import styles from "./Text.module.sass";
import Form from "./Form";
import Typed from 'react-typed';

  const Text = () => {
    return (
        <div className={styles.half}>
            <div className={cn("h2", styles.message)}>Research & observe <span>journeys</span> of software companies that are 
            <span> <Typed className={styles.typed}
                strings= {[
                  "out of business ",
                  "still active ",
                  "just recently launched ",
                ]}
                typeSpeed= {90}
                loop= {true}
                backSpeed= {30}
                backDelay= {2500}
                ></Typed> 
              </span></div>
            <div className={cn("h5", styles.note)}>Browse, collect, and present data seamlessly</div>
            <div className={cn("base-1b", styles.caption)}>We're currently building our platform.<br/> Subscribe to our newsletter to get the updates when we launch.</div>
            <Form />
        </div>
    );
};
  
  export default Text;