/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import styles from "./Home.module.sass";
import Hero from "../../components/HomeSections/Hero";
import Features from "../../components/HomeSections/Features";
import Subscription from "../../components/HomeSections/Subscription";




const Home = () => {
  return (
    <>
      <div className={styles.home}>
        <Hero />
        <Features />
        <Subscription />
      </div>
    </>
  );
};

export default Home;
